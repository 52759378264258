
import { defineComponent } from 'vue';
import { mapGetters, mapMutations } from 'vuex';

export default defineComponent({
  computed: {
    classes(): object {
      return {
        [`${String(this.$route.name)}-page`]: true,
      };
    },

    ...mapGetters([
      'themeClass',
    ]),
  },

  watch: {
    themeClass(newVal: string) {
      const $body = document.querySelector('body');
      if ($body) $body.className = newVal;
    },
  },

  methods: {
    ...mapMutations(
      [
        'TOGGLE_DARK_MODE',
      ],
    ),
  },

  beforeMount() {
    const $body = document.querySelector('body');
    if ($body) $body.className = this.themeClass;
  },
});
